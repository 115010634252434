// Cold colors
$aqua: #7fdbff;
$blue: #0074d9;
$navy: #001f3f;
$teal: #39cccc;
$green: #2ecc40;
$olive: #3d9970;
$lime: #01ff70;

// Warm colors
$yellow: #ffdc00;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;

$primary-color: #FF0000;
$text-color: #111111;

$link-color: #0074D9;

$color1: #55C5E8;
$color2: #FFEA55;
$color3: #54CEC9;
$color4: #FFB6D4;
$color5: #8760BC;
$color6: #5A61FF;
$color7: #F86F54;
$color8: #A645F4;
$color9: #FF9450;
$border-radius: 100px;

// Grayscale
$white: #ffffff;
$silver: #dddddd;
$gray: #aaaaaa;
$black: #111111;

$main-background-color: $white;
$main-text-color: $black;
$divider-color: rgba($black, .14);

$main-text-size: 14px;
$main-border-radius: 6px;

$small-space: 16px;
$medium-space: 32px;
$large-space: 64px;


$desktop: 1150px;
$mobile: 768px;