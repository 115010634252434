@import './_variables';
@import './_mixins';

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

html,
body {
  height: auto;
  overflow-x: hidden;
}


body {
  position: relative;
  margin: 0;
  font-family: "DM Sans", sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-text-color;
  background-color: $main-background-color;
  font-size: $main-text-size;
}

.color1 {
  background-color: $color1;
}

.color2 {
  background-color: $color2;
}

.color3 {
  background-color: $color3;
}

.color4 {
  background-color: $color4;
}

.color5 {
  background-color: $color5;
}

.color6 {
  background-color: $color6;
}

.color7 {
  background-color: $color7;
}

button.button,
button.button.outlined {
  &.filled {
    &:hover {
      background-color: darken($primary-color, 20%);
    }
  }

  &.color1 {
    background-color: $color1;
  }

  &.color2 {
    background-color: $color2;
  }

  &.color3 {
    background-color: $color3;
  }

  &.color4 {
    background-color: $color4;
  }

  &.color5 {
    background-color: $color5;
  }

  &.color6 {
    background-color: $color6;
  }

  &.color7 {
    background-color: $color7;
  }

  &.color8 {
    background-color: $color8;
  }

  &:hover {
    &.color1 {
      background-color: darken($color1, 20%);
    }

    &.color2 {
      background-color: darken($color2, 20%);
    }

    &.color3 {
      background-color: darken($color3, 20%);
    }

    &.color4 {
      background-color: darken($color4, 20%);
    }

    &.color5 {
      background-color: darken($color5, 20%);
    }

    &.color6 {
      background-color: darken($color6, 20%);
    }

    &.color7 {
      background-color: darken($color7, 20%);
    }

  }

  &.outlined {
    color: $text-color;

    &.color1 {
      background-color: transparent;
      border: 1px solid $color1;
      color: $color1;
    }

    &.color2 {
      background-color: transparent;
      border: 1px solid $color2;
    }

    &.color3 {
      background-color: transparent;
      border: 1px solid $color3;
    }

    &.color4 {
      background-color: transparent;
      border: 1px solid $color4;
    }

    &.color5 {
      background-color: transparent;
      border: 1px solid $color5;
    }

    &.color6 {
      background-color: transparent;
      border: 1px solid $color6;
    }

    &.color7 {
      background-color: transparent;
      border: 1px solid $color7;
    }

    &.color8 {
      background-color: transparent;
      border: 1px solid $color8;
    }

    &:hover {
      &.color1 {
        background-color: $color1;
        color: $white;
      }

      &.color2 {
        background-color: $color2;
        color: $white;
      }

      &.color3 {
        background-color: $color3;
        color: $white;
      }

      &.color4 {
        background-color: $color4;
        color: $white;
      }

      &.color5 {
        background-color: $color5;
        color: $white;
      }

      &.color6 {
        background-color: $color6;
        color: $white;
      }

      &.color7 {
        background-color: $color7;
        color: $white;
      }

      &.color8 {
        background-color: $color8;
        color: $white;
      }

    }
  }
}

.fill-color1 {
  color: $color1;
}

.fill-color2 {
  color: $color2;
}

.fill-color3 {
  color: $color3;
}

.fill-color4 {
  color: $color4;
}

.fill-color5 {
  color: $color5;
}

.fill-color6 {
  color: $color6;
}

.fill-color7 {
  color: $color7;
}

.fill-color8 {
  color: $color8;
}

h1 {
  font-size: 66px;
  font-weight: 700;
  line-height: 120%;
}

h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
}

h3 {
  font-size: 26px;
  line-height: 120%;
  font-weight: 600;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: $mobile) {
    :first-child {
      max-width: 320px;
    }
  }
}