/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 30px;
    top: 30px;

    @media(min-width: 1150px) {
        display: none;
    }
}

#react-burger-cross-btn {
    height: 40px !important;
    width: 40px !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #8760BC;
    height: 4px !important;
    border-radius: 8px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #6b20d5;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 40px !important;
    width: 40px !important;
    right: 20px !important;

}

/* Color/shape of close button cross */
.bm-cross {
    height: 40px !important;
    width: 2px !important;
    background: #8760BC;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start !important;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

.bm-item-list li {
    list-style: none;
    margin-top: 32px;
    text-align: left;
    width: 100%;
}

.bm-item a {
    color: #000000;
    text-decoration: none;
    margin: 10px 0;
    font-size: 1.5em;

}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}