@import '../../styles/variables.scss';

.video {
    h1 {
        margin-top: 65px;
        font-size: 48px;
    }

    .items {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 48px;
        justify-content: center;

        .video-card {
            max-width: 386px;
            margin-top: 16px;

            h3 {
                margin-top: 16px;
                font-size: 26px;
            }

            p {
                margin-top: 8px;
                font-size: 16px;
            }

            .video-card__img {
                position: relative;
                cursor: pointer;

                img.cover {
                    width: 386px;
                    height: 238px;
                    object-fit: cover;
                    border-radius: 30px
                }

                img.play {

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
            }
        }
    }

    @media(max-width: $desktop) {
        h1 {
            text-align: center;
            margin: 40px;
            font-size: 26px;
        }

        .items {
            flex-direction: column;
            align-items: center;

            .video-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 320px;


                .video-card__content {
                    h3 {
                        font-size: 18px;
                    }
                }

                .video-card__img {
                    display: flex;
                    justify-content: center;

                    img.cover {
                        width: 320px;
                        height: 200px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .cta {
        margin-bottom: 80px;
    }
}