@import "../../styles/variables.scss";

.seo {
    h1 {
        font-size: 48px;
        margin-top: 65px;
        text-align: center;
    }

    .seo-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        margin-top: 48px;

        h2 {
            margin-top: 16px;
            font-size: 48px;
            text-align: center;
            margin-bottom: 32px;
        }

        .card {
            margin-left: 0px;
            display: flex;
            flex-direction: row-reverse;
            max-width: 900px;
            width: 100%;
            padding: 40px;
            position: relative;
            padding-top: 0;
            justify-content: flex-end;
            height: 100%;
            z-index: 1;

            .bullets {
                max-width: 525px;


                ul {
                    li {
                        list-style-type: none;
                        margin-bottom: 16px;
                        font-size: 18px;
                        line-height: 150%;
                        display: flex;
                        align-items: center;

                        .svg-wrapper {
                            margin-right: 16px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                h4 {
                    margin: 40px 0;
                    font-size: 24px;
                }
            }

            .header {
                position: absolute;
                top: -2px;
                bottom: -2px;
                border-radius: 200px;
                z-index: 2;
                right: -125px;
                width: 290px;
                display: flex;
                flex-direction: column;
                padding: 20px;
                justify-content: center;
                color: white;

                h3 {
                    font-size: 40px;
                    text-align: center;
                }

                p.package {
                    font-size: 20px;
                    text-align: center;
                    margin-top: 8px;
                }

                p.body {
                    font-size: 20px;
                    text-align: center;
                }

                p.price {
                    font-size: 26px;
                    text-align: center;
                    margin: 40px 0;
                    font-weight: bold;
                }

                background-color: aqua;
            }

            .redirect {
                display: flex;
                justify-content: center;
                position: relative;
                background: transparent;
                border: none;

                .arrow {
                    margin-left: -20px;
                    z-index: 1;
                }

                .medal {
                    position: relative;
                    z-index: 2;
                    width: 94px;
                    height: 94px;

                    .cover {
                        z-index: 3;
                        border-radius: 100px;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        position: relative;
                        z-index: 1;
                    }
                }

                .mobile {
                    display: none;
                }
            }



            border: 2px solid #000000;

            border-radius: 30px;
            min-height: 450px;

            .content {
                padding: 20px 30px;
                font-size: 16px;
                line-height: 150%;

                p.body {
                    margin-top: 16px;
                }

                a {
                    display: inline;
                }
            }
        }
    }

    @media(max-width: $desktop) {
        h1 {
            font-size: 1.7rem;
        }


        .seo-content {

            h2 {
                font-size: 1.5rem;
            }

            .card {

                flex-direction: column-reverse;
                margin-left: 0;
                padding: 20px;
                justify-content: center;
                align-items: center;
                width: auto;
                max-width: fit-content;
                margin: 0 20px;


                p.bullet {
                    font-size: 1rem;
                }

                .bullets {
                    h4 {
                        font-size: 1.2rem;
                    }
                }

                .header {
                    position: relative;
                    width: 100%;
                    border-radius: 20px;
                    margin-bottom: 20px;
                    right: 0;

                    p.body {
                        line-height: 150%;
                        font-size: 1rem;
                    }

                    p.price {
                        font-size: 1.4rem;
                        margin: 16px 0;
                    }
                }


                .medal {
                    display: none;
                }

                .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        font-size: 26px;
                    }

                    .package {
                        font-size: 20px;
                        margin-left: 8px;
                    }
                }


                .redirect {
                    flex-direction: column;
                    align-items: center;

                    .arrow {
                        display: none;
                    }

                    .mobile {
                        display: block;
                        margin-top: 20px;
                        height: 40px;
                        width: auto;
                    }
                }
            }
        }
    }

    .cta {
        margin-bottom: 80px;
    }
}