@import '../../styles/variables.scss';

.landing {
    width: 100%;
    margin-top: 64px;

    .initial {
        width: 100%;
        background: linear-gradient(to left, #A3A3A3, #E1E2E2);
        border-radius: 30px;
        padding: 0 82px;
        position: relative;


        h1 {
            padding-top: 146px;
        }

        h3 {
            padding-top: 32px;
        }


        .buttons {
            padding-top: 64px;


            button.myworks {
                color: $primary-color ;
            }

            .button:last-child {
                margin-left: 32px;
            }
        }



        .cards {
            padding-top: 156px;
            padding-bottom: 32px;
            z-index: 2;
            position: relative;
            display: flex;
            justify-content: space-between;

            .row {
                display: flex;
                justify-content: space-between;
                gap: 16px;
                width: 45%;
            }

            .benefit-card {
                width: 205px;
                height: 62px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                p {
                    margin: 0 16px;
                    font-size: 18px;
                }

                &.color1 {
                    background-color: rgba($color1, 0.7)
                }

                &.color2 {
                    background-color: rgba($color2, 0.7)
                }

                &.color3 {
                    background-color: rgba($color3, 0.7)
                }

                &.color4 {
                    background-color: rgba($color4, 0.7)
                }
            }
        }

        img.mark1 {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;

            height: 100%;
            width: auto;
        }

        @media (max-width: $desktop) {
            padding: 0 16px;
            align-items: center;
            display: flex;
            flex-direction: column;

            h1 {

                font-size: 64px;
                text-align: center;
                padding-top: 24px;
            }

            h3 {
                // font-size: 18px;
                text-align: center;
                padding-top: 24px;

            }

            img.mark1 {
                position: relative;
                width: 100%;
                height: auto;

            }

            .buttons {
                display: flex;
                flex-direction: column;
                padding-top: 32px;
                width: 100%;
                align-items: center;

                a {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    text-decoration: none;
                }

                button {
                    max-width: 300px;
                    width: 100%;
                    margin-top: 16px;

                    &.outlined {
                        color: $primary-color;
                    }

                    // margin: 0;
                    &:last-child {
                        margin-left: 0 !important;
                        margin-top: 16px;
                    }
                }
            }

            .cards {
                flex-wrap: wrap;
                justify-content: center;
                gap: 16px;
                padding-top: 32px;

                .benefit-card {
                    height: 28px;
                    max-width: 280px;
                    width: fit-content;


                    p {
                        font-size: 12px;
                    }
                }
            }
        }

        @media (max-width: $mobile) {
            padding: 0;

            h1 {
                font-size: 48px;
            }

            h3 {
                font-size: 18px;
            }


            border-radius: 10px;

            .cards {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .row {
                    display: flex;
                    justify-content: center;
                    gap: 0;
                    width: 100%;

                }

                .benefit-card {
                    // height: 28px;
                    // max-width: 280px;

                    width: fit-content;

                    p {
                        font-size: 12px;
                        padding: 4px;
                        margin: 0;
                        text-align: center;
                    }

                    &:last-child {
                        margin-left: 8px;
                    }
                }
            }

        }
    }

    .brands {
        margin-top: 155px;
        position: relative;

        a {
            text-decoration: none;
        }

        h2 {
            text-align: center;
        }



        .brand-slider {
            margin-top: 160px;
            margin-bottom: 90px;

            .row {
                display: flex;
                justify-content: space-between;

                &:last-child {
                    margin-left: 128px;
                }

            }

            .brand {
                margin-left: 32px;

                .brand-card {
                    width: 348px;
                    height: 160px;
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    color: $text-color;


                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 100%;
                        margin: 0 16px;
                    }

                    h4 {
                        font-size: 24px;
                        font-weight: 700;
                    }
                }
            }
        }

        .brand-list {
            display: none;
        }

        @media(max-width: $desktop) {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                font-size: 26px;
                max-width: 300px;
            }

            .brand-slider {
                display: none;
            }

            .brand-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 8px;
                margin-top: 32px;

                .brand-card {
                    width: 170px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    color: $text-color;
                    margin: 0 16px;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        margin: 0 16px;
                    }

                    h4 {
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }
        }

        @media(max-width: $mobile) {
            margin-top: 80px;

            h2 {
                font-size: 20px;
            }

            .brand-list {
                .brand-card {
                    width: 140px;
                    height: 80px;
                    border-radius: 10px;
                    margin: 0;

                    img {
                        width: 24px;
                        height: 24px;
                        margin: 0 8px;
                    }

                    h4 {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .why-me {
        margin-top: 180px;

        .text {
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;

            h2 {
                margin-top: 16px;
                max-width: 800px;
                ;
            }

            h4 {
                font-size: 26px;
            }
        }


        .cards {
            display: flex;
            flex-wrap: wrap;
            gap: 22px;
            margin-top: 120px;
            justify-content: center;

            .card {
                position: relative;
                border-radius: 20px;
                padding: 40px 20px;
                width: 387px;
                height: 570px;

                .header {
                    display: flex;

                    h5 {
                        font-size: 26px;
                        font-weight: 600;
                        margin-left: 16px;
                    }
                }

                p {
                    text-align: left;
                    margin-top: 16px;
                    font-size: 18px;
                    line-height: 150%;
                }

                .img-wrapper {
                    position: absolute;
                    width: 387px;
                    height: 400px;
                    bottom: 0;
                    left: 0;


                    img {}

                    overflow: hidden;
                }

                &.card-1 {
                    background: linear-gradient(to bottom, #FFB6D4, #ffb6d400);
                }

                &.card-2 {
                    background: linear-gradient(to bottom, #FF9450, #FF945000);
                }

                &.card-3 {
                    background: linear-gradient(to bottom, #54CEC9, #54CEC900);
                }
            }
        }

        @media(max-width: $desktop) {
            .text {
                h2 {
                    font-size: 22px;
                    line-height: 150%;
                }

                h4 {
                    font-size: 18px;
                }
            }

            .cards {
                margin-top: 64px;

                .card {
                    height: 350px;

                    p {
                        font-size: 16px;
                    }

                    .header {

                        h5 {
                            font-size: 22px;
                        }
                    }

                    .img-wrapper {
                        width: 100%;
                        height: 200px;
                        justify-content: center;
                        display: flex;

                        img {
                            width: auto;
                            height: 100%;
                            object-fit: fill;
                        }
                    }
                }
            }
        }

        @media(max-width: $mobile) {
            margin-top: 80px;

            .cards {
                .card {
                    border-radius: 10px;

                    .img-wrapper {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    .portfolio {
        h2 {
            text-align: center;
            margin-top: 180px;
        }

        .portfolio-content {
            display: flex;
            margin-top: 74px;
            align-items: center;

            .video {
                position: relative;

                .mark {
                    position: absolute;
                    bottom: 80px;
                    left: 40px;
                    z-index: 2;
                }

                .back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                .frame {
                    position: relative;
                    z-index: 3;
                }

            }

            .examples {
                margin-left: 64px;

                .example {
                    margin-top: 80px;

                    &:first-child {
                        margin-top: 0;
                    }

                    h5 {
                        font-size: 24px;
                        font-weight: 600;
                    }

                    p {
                        margin-top: 16px;
                        font-size: 18px;
                        line-height: 150%;
                    }

                    a {
                        font-weight: 800;
                    }
                }
            }
        }

        @media(max-width: $desktop) {
            h2 {
                font-size: 26px;
            }

            padding: 0 20px;

            .portfolio-content {
                flex-direction: column;
                align-items: center;
                flex-direction: column-reverse;

                .video {
                    margin-top: 32px;

                    .frame {
                        width: 100%;
                        height: auto;
                    }

                    .back {
                        width: 100%;
                        height: auto;
                    }

                    .mark {
                        bottom: 40px;
                        left: 20px;
                        width: 170px;
                        height: auto;
                    }

                }

                .examples {
                    margin-left: 0;

                    .example {
                        h5 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        @media(max-width: $mobile) {
            padding: 0;

            h2 {
                margin-top: 80px;
            }

            .portfolio-content .video .mark {
                width: 120px;

            }
        }
    }


    .testimonials {
        margin-top: 180px;

        .testimonial {
            border-radius: 30px;
            padding: 40px;
            position: relative;
            background: linear-gradient(to left bottom, #A3A3A3, #A3A3A300);
            justify-content: space-between;
            display: flex;

        }

        p {
            line-height: 150%;
        }

        p.name {
            font-size: 48px;
            font-weight: 800;
        }

        p.position {
            font-size: 26px;
            font-weight: 600;
            color: $color8;
        }

        p.body {
            margin-top: 32px;
            font-size: 18px;
            max-width: 330px;
        }

        p.body2 {
            max-width: 245px;
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card {
                max-width: 320px;
                padding: 40px 20px;
                border-radius: 30px;
                margin-top: 16px;
                z-index: 2;

                &:first-child {
                    margin-top: 0;
                }

                h5 {
                    font-size: 26px;
                    font-weight: 600;
                }

                p {
                    margin-top: 16px;
                    font-size: 16px;
                }

                &.color9 {
                    background-color: rgba($color9, 0.7);
                }

                &.color5 {
                    background-color: rgba($color5, 0.7);
                }

                &.color1 {
                    background-color: rgba($color1, 0.7);

                }
            }
        }

        img.review {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 12%;
            width: 690px;
            height: auto;

            @media (max-width: $mobile) {
                right: 38%;
            }
        }

        @media(max-width: $desktop) {
            .testimonial {
                flex-direction: column;
                align-items: center;
                padding-bottom: 0;

                p.name {
                    font-size: 36px;
                }

                p.position {
                    font-size: 24px;
                }

                p.body {
                    max-width: 100%;
                }



                .description {
                    margin-top: 32px;

                    .card {
                        max-width: 100%;
                    }

                }
            }

            img.review {
                position: relative;
                width: 100%;
                height: auto;
                margin-right: -300px;
            }
        }

        @media (max-width: $mobile) {
            margin-top: 80px;

            .testimonial {
                border-radius: 10px;

                .description {
                    .card {
                        border-radius: 10px;
                        padding: 16px;
                    }
                }
            }


        }
    }

    .faq {
        h2 {
            text-align: center;
            margin-top: 180px;
            font-size: 48px;
            font-weight: 800;
        }

        .body4 {
            text-align: center;
            font-weight: 700;
            font-size: 26px;
            line-height: 150%;
        }

        .body5 {
            font-size: 20px;
        }

        .faqs {
            .faq-item {
                background: linear-gradient(to left bottom, #A3A3A380, #A3A3A320);
                margin-bottom: 20px;
                padding: 40px;
                border-radius: 40px;
                // color: $color-text;
                cursor: pointer;

                height: 115px;
                transition: height 0.3s ease-in-out;



                .question {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .answer {
                    margin-top: 20px;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;

                    &.expanded {
                        opacity: 1;
                    }
                }

                &:first-child {
                    margin-top: 80px;
                }

                &.expanded {
                    height: 190px;
                }

                svg {
                    width: 32px;
                    height: 32px;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(90deg);

                }

                svg.open {
                    transform: rotate(0deg);
                }
            }
        }

        @media(max-width: $desktop) {
            h2 {
                font-size: 26px;
            }

            .body4 {
                font-size: 22px;
            }

            .body5 {
                font-size: 18px;
            }

            .faqs {
                .faq-item {
                    padding: 20px;
                    height: 100px;

                    .question {
                        align-items: flex-start;

                        .body4 {
                            font-size: 20px;
                        }

                    }

                    .answer {
                        margin-top: 10px;

                        .body5 {
                            font-size: 16px;
                        }
                    }

                    svg {
                        width: 24px;
                        height: 24px;

                    }
                }
            }
        }

        @media (max-width: $mobile) {
            h2 {
                margin-top: 80px;
            }

            .faqs {
                .faq-item {
                    border-radius: 10px;

                    .question {
                        align-items: flex-start;

                        .body4 {
                            font-size: 16px;
                        }
                    }

                    &.expanded {
                        height: 250px;
                    }
                }
            }

        }
    }

    .reviews {
        margin-top: 180px;

        .mobile {
            display: none;

            .review {

                padding: 15px;
                display: flex;
                border: 1px solid $color6;
                border-radius: 20px;
                margin-bottom: 20px;

                .review__content {
                    margin-left: 15px;

                    h4 {
                        margin-bottom: 8px;

                    }

                    p.text {

                        line-height: 150%;
                    }

                    .review__rating {
                        display: flex;
                        margin: 8px 0;
                        align-items: center;

                        p {
                            font-weight: 600;
                            margin-left: 8px;


                            &.ago {
                                font-size: 14px;
                                font-weight: 400;
                                color: $gray;
                                margin-left: 16px;
                            }
                        }

                        .stars {
                            img {
                                width: 20px;
                                height: 20px;
                            }

                            @media (max-width: $mobile) {
                                img {
                                    width: 12px;
                                    height: 12px;
                                }

                            }
                        }
                    }
                }
            }
        }

        .scroller {
            margin-top: 36px;

            img {
                margin-left: 36px;
            }
        }

        @media(max-width: $desktop) {
            margin-top: 64px;

            .mobile {
                display: block;
            }

            .desktop {
                display: none;
            }

        }

        @media (max-width: $mobile) {
            margin-top: 80px;

        }
    }

    .services {
        margin-top: 180px;

        .mobile {
            display: none;
        }

        @media (max-width: $desktop) {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
            }

        }

        .content {
            display: flex;
            width: 100%;
            margin-top: 40px;

            .btns {
                display: flex;
                flex-direction: column;

                .button {
                    margin-top: 16px;
                    border-radius: 20px;
                    border-width: 2px;
                    width: 387px;


                    p {
                        padding: 26px 38px;
                        font-size: 24px;
                        font-weight: 600;
                        text-align: left;
                        color: $text-color
                    }

                    &.outlined {
                        p {

                            padding: 24px;
                            padding-left: 38px;
                        }
                    }

                    &:first-child {
                        margin-top: 0;

                    }
                }
            }

            .service {
                margin-left: 32px;
                width: 100%;

                .card {
                    padding: 40px;
                    border-radius: 30px;
                    width: 100%;
                    height: 443px;

                    @media (max-width: $mobile) {
                        border-radius: 10px;

                    }

                    h3 {
                        font-size: 26px;
                        font-weight: 600;
                    }

                    p.text {
                        margin-top: 20px;
                        font-size: 18px;
                        line-height: 150%;
                    }
                }

                .screenwriting {
                    position: relative;
                    background: linear-gradient(to top right, #80CCE4, #848383);

                    p.text {
                        max-width: 487px;
                    }

                    ol {
                        list-style: inside decimal;
                        margin-top: 18px;
                        font-size: 20px;

                        li {
                            margin-top: 4px;

                            &::marker {
                                font-weight: 800;
                            }
                        }

                    }

                    img {
                        position: absolute;
                        bottom: -30px;
                        right: 0;
                    }

                }

                .youtubeSEO {
                    display: flex;
                    background: #FFEA55;
                    align-items: center;

                    .content {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        margin-top: 0;

                    }

                    img {
                        margin-left: 32px;
                        width: 400px;
                        height: 270px;

                    }
                }

                .thumbnail {
                    background: $color4;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    h3 {
                        width: 100%;
                        text-align: left;
                    }

                    img {
                        margin-top: 36px;
                        max-height: 250px;
                    }
                }

                .editing {
                    background: linear-gradient(to right top, $color3, #FFB6D450);

                    img {
                        max-height: 200px;
                        // width: 100%;
                        // width: auto;
                        margin-top: 36px;
                    }
                }

                .voiceover {
                    background: linear-gradient(to right top, $color8, #FFB6D450);
                    display: flex;

                    .content {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                    }

                    img {
                        margin-left: 36px;

                    }

                }
            }
        }

        @media(max-width: $desktop) {
            h2 {
                font-size: 1.7rem;
                width: 100%;
                text-align: center;
            }

            .content {
                flex-direction: column;
                align-items: center;

                .btns {
                    margin-top: 32px;
                    flex-direction: row;
                    gap: 16px;

                    .button {
                        width: 100%;
                    }
                }

                .service {
                    margin-left: 0;
                    margin-top: 32px;
                    width: 100%;

                    .card {
                        height: auto;
                        margin-top: 16px;

                        h3 {
                            width: 100%;
                            text-align: left;
                        }

                        p.text,
                        ol {
                            font-size: 1rem;
                        }
                    }

                    .screenwriting {
                        display: flex;
                        flex-direction: column;

                        align-items: flex-end;

                        p.text {
                            width: 100%;
                            max-width: 100%;
                        }

                        ol {
                            width: 100%;
                            max-width: 100%;
                        }

                        img {
                            position: relative;
                            width: auto;
                            height: 240px;
                            margin-right: -40px;

                        }
                    }

                    .youtubeSEO {
                        flex-direction: column;
                        align-items: center;

                        img {
                            width: 100%;
                            height: auto;
                            margin-top: 32px;
                            margin: 0;
                            margin-top: 16px;
                        }
                    }

                    .thumbnail {
                        img {
                            width: 100%;
                            height: auto;
                            margin-top: 32px;
                        }
                    }

                    .editing {
                        img {

                            width: auto;
                            // height: 50px;
                            margin-top: 32px;
                        }

                        @media (max-width: $mobile) {
                            img {
                                width: 100%;
                                height: auto;
                                margin-top: 16px;

                            }
                        }
                    }

                    .voiceover {
                        flex-direction: column;

                        img {
                            margin: 0;
                            width: 100%;
                            height: auto;
                            margin-top: 32px;
                        }
                    }
                }
            }
        }

        @media (max-width: $mobile) {
            margin-top: 80px;

        }
    }
}

.scroller {}

.scroller__inner {
    display: flex;
    flex-wrap: wrap;
    // gap: 1rem;
}

.scroller[data-animated="true"] {
    overflow: hidden;
    // -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    mask: linear-gradient(90deg, transparent, white 20%, white 90%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
    --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
    --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
    --_animation-duration: 120s;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}

.cta {
    text-align: center;
    border-radius: 30px;
    border: 2px solid $color6;
    margin-top: 160px;
    padding: 40px 80px;

    &.bottom {
        margin-top: 180px;
        margin-bottom: 160px;
    }

    @media(max-width: $desktop) {
        margin: 0 20px;
        margin-top: 80px;
        padding: 40px 20px;

        h2 {
            font-size: 26px;
            line-height: 150%;
        }
    }

    @media (max-width: $mobile) {
        border-radius: 10px;
        margin: 0;
    }
}