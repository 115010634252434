@import '../styles/variables.scss';

.layout {
    font-family: "DM Sans", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    main {
        max-width: 1205px;
        width: 100%;
    }


    nav {
        width: 100%;
        display: flex;

        justify-content: center;

        .desktop-nav {
            max-width: 1205px;
            width: 100%;
            height: 106px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid $color5;

        }

        .links {
            display: flex;
            align-items: center;

            .button {
                margin: 0 32px;
            }

            ul {
                display: flex;
                list-style: none;

                li {
                    margin-left: 32px;

                    a {
                        text-decoration: none;
                        color: $main-text-color;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }
        }

        img.logo {
            height: 46px;
            margin-left: 16px;
        }
    }



    @media (max-width: $desktop) {
        nav {
            height: 106px;

            .desktop-nav {
                position: fixed;
                z-index: 99;
                height: 90px;
                background-color: $white;

                &.scrolled {
                    height: 60px;

                    .bm-burger-button {
                        top: 14px;
                    }

                }
            }

            .links {
                display: none;
            }

            button.contactme {
                display: none;
            }

            .burger-menu {
                display: flex;
                align-items: center;
                margin-right: 16px;


                .line {
                    background-color: $main-text-color;
                    width: 32px;
                    height: 4px;
                    margin: 4px 0;
                }

                .line:nth-child(1) {
                    width: 24px;
                    height: 2px;

                }

                .line:nth-child(2) {
                    width: 16px;
                }

                .line:nth-child(3) {
                    width: 20px;
                }
            }
        }
    }


    footer {
        width: 100%;
        display: flex;
        justify-content: center;

        .content {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            border-top: 2px solid $color5;
            max-width: 1205px;
            width: 100%;
        }

        .brand {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            padding-bottom: 0;

            img.logo {
                height: 32px;
                margin-left: 16px;
            }

            .social {
                img {
                    margin-right: 16px;
                }
            }

        }


        .verdix {

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin-top: 0;

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
            }

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: $gray;
                text-decoration: underline;
            }

            img {
                height: 22px;
                margin-left: 16px;
                width: auto;
            }
        }

        .footer-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 32px;
            padding-top: 0;

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: $gray;
            }

            a {
                text-decoration: none;
                color: $gray;
            }
        }

        @media(max-width: $desktop) {
            flex-direction: column;
            align-items: center;

            .brand {
                flex-direction: column;
                align-items: center;

                .logo {
                    margin: 16px;
                }

                .social {
                    margin-top: 16px;
                }

                .verdix {
                    margin: 16px;

                    img {
                        margin-top: 32px;
                        margin: 0;
                    }
                }
            }

            .social {
                margin-top: 16px;
            }

            .verdix {
                margin: 16px;

                img {
                    margin-top: 32px;
                    margin: 0;
                }
            }

            .footer-links {
                width: 100%;
                justify-content: space-between;
            }
        }

        @media(max-width: $mobile) {
            .content {
                .brand {
                    align-items: flex-start;

                    img.logo {
                        margin: 0;
                    }
                }

                .verdix {
                    flex-direction: column;
                    align-items: flex-start
                }

                .footer-links {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 16px;
                }
            }

        }
    }



    .scroll-button {
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 99;
        background: transparent;
        border: none;
        display: none;

        img {
            width: 60px;
            height: 60px;
        }

        @media(max-width: $desktop) {
            display: block;
        }
    }

}

button.button {

    font-family: "DM Sans", sans-serif;
    border-radius: 100px;
    border: none;
    transition: background-color 0.3s ease;

    &:hover {
        cursor: pointer;
    }

    p {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 15px 40px;
    }

    background-color: $primary-color;
    color: $white;

    &.outlined {
        background-color: transparent;
        border: 2px solid $primary-color;
        color: $primary-color;
    }
}

.cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    border: 2px solid $color6;
    // border-radius: 30px;
    padding: 40px;

    .button {
        margin-top: 32px;
    }
}

.pagination {
    position: relative;
    z-index: 4;

    .pagination-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;

        img.arrow {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin: 0 16px;

            &.previous {
                transform: rotate(180deg);
            }

            &.disabled {
                opacity: 0.5;
            }
        }


        .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color6;
            opacity: 0.5;
            margin: 0 8px;

            &.active {

                opacity: 1;
            }
        }
    }
}

.roadmap {
    margin-top: 160px;
    background: linear-gradient(180deg, #8f8b8b72 0%, #FFFFFF 100%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;

    h2 {
        margin-top: 80px;
        margin-left: 40px;
    }

    .steps {
        width: 100%;
        justify-content: center;
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding: 40px;
        position: relative;

        img {
            top: -20px;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: none;
        }
    }

    .step {
        max-width: 550px;
        margin-bottom: 16px;
        padding: 20px 40px;
        border-radius: 30px;
        opacity: 0.9;
        position: relative;
        z-index: 2;

        p {
            margin-top: 16px;
            font-size: 18px;
            line-height: 150%;
        }

        @media (max-width: $desktop) {

            border-radius: 10px;

        }
    }

    /* Odd step indexes will start from the right */
    .step:nth-child(odd) {
        margin-right: 580px;
    }

    /* Even step indexes will start from the left */
    .step:nth-child(even) {
        margin-left: 580px;
    }

    @media(max-width: $desktop) {
        margin-top: 80px;

        h2 {
            text-align: center;
            margin: 40px;
            font-size: 26px;
        }

        .steps {
            padding: 20px;
            margin-top: 0;
        }

        .step {
            max-width: 100%;
            margin-right: 0;
            margin-left: 0;

            p {
                font-size: 16px;
            }

            h3 {
                font-size: 20px;
            }
        }

        .step:nth-child(odd) {
            margin-right: 0
        }

        /* Even step indexes will start from the left */
        .step:nth-child(even) {
            margin-left: 0;
        }

        img {
            display: none;
        }
    }
}