@import '../../styles/variables.scss';

.script {
    margin-bottom: 80px;

    h1 {
        margin-top: 65px;
        font-size: 48px;
    }

    img {
        object-fit: cover;
        width: 387px;
        height: 257px;
        border-radius: 30px 30px 0 0;
    }

    .scripts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin-top: 48px;
    }

    .card {
        max-width: 387px;
        border-radius: 30px;
        min-height: 450px;

        a {
            text-decoration: none;
            color: $text-color;
        }

        .content {
            padding: 20px 30px;
            font-size: 16px;
            line-height: 150%;

            p.body {
                margin-top: 16px;

            }

            span {
                margin-left: 8px;
                display: inline;
                text-decoration: underline;
                color: $link-color;
            }

            a {
                display: inline;
            }
        }
    }

    @media (max-width: $desktop) {
        h1 {
            font-size: 26px;
            text-align: center;
            margin: 32px 16px;
        }

        .card {
            max-width: 320px;
            border-radius: 10px;
            min-height: 322px;

            img {
                width: 320px;
                height: 180px;
                border-radius: 10px 10px 0 0;
            }

            h3 {
                font-size: 20px;
            }

            p.body {
                margin-top: 16px;
                font-size: 15px;
            }
        }
    }

}