.privacy-policy {
    padding: 32px;

    h1 {
        font-size: 24px;
        margin-bottom: 16px;
    }

    h2 {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 12px;
    }

    p {
        font-size: 16px;
        margin-bottom: 12px;
    }
}